.pageHeader {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.tools {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.tools button {
  align-items: center;
  display: flex;
  margin-left: 10px;
}

.tools button svg {
  margin-right: 8px;
}

.status {
  border-radius: 12px;
  font-size: 12px;
  font-weight: 700;
  padding: 5px 10px 5px 10px;
  width: fit-content;
}

.status.completed {
  background: #E6FBD9;
  color: #0F5B1D;
}

.status.error {
  background: #D92D20;
  color: white;
}

.status.warn {
  background: #DC6803;
  color: white;
}
