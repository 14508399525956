.wrapper {
  display: grid;
  grid-template-columns: 1fr;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
}

.formAreaWrapper {
  column-gap: 20px;
  display: grid;
  grid-template-columns: 1fr;
}

.formBlock {
  background: #FFFFFF;
  border-radius: 8px;
  padding: 20px;
  position: relative;
  margin-top: 20px;
}

.blockTitle {
  font-size: 16px;
  margin-bottom: 10px;
}

.label {
  font-size: 14px;
}

.routeLabel {
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}

.routeItem {
  column-gap: 10px;
  display: grid;
  grid-template-columns: 75px 1fr 100px 150px;
  margin-bottom: 10px;
}

.buttons {
  align-items: center;
  display: flex;
  justify-self: end;
  margin-top: 20px;
}

.buttons button {
  width: 122px;
}

.buttons button:last-of-type {
  margin-left: 10px;
}
