.pageHeader {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.tools {
  align-items: center;
  display: flex;
}

.tools button{
  margin-left: 10px;
}

.resultsFilter {
  margin-right: 10px;
  width: 200px !important;
}
