.wrapper {
  display: grid;
  grid-template-columns: 1fr;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
}

.formBlock {
  background: #FFFFFF;
  border-radius: 8px;
  padding: 20px;
  position: relative;
  margin-top: 20px;
}

.blockTitle {
  font-size: 16px;
  margin-bottom: 10px;
}

.label {
  font-size: 14px;
}

.doubleInputArea {
  column-gap: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.buttons {
  align-items: center;
  display: flex;
  justify-self: end;
  margin-top: 20px;
}

.buttons button {
  width: 122px;
}

.buttons button:last-of-type {
  margin-left: 10px;
}

.error {
  color: red;
  margin-top: 10px;
  text-align: right;
}
