:root {
  --app-padding-left-right: 60px;
  --app-min-height: 500px;
  --app-primary-color: #3A36DB;
  --app-background-color: #F9F8FE;
  
  --header-height: 100px;
  --footer-height: 170px;

  --text-primary-color: #000000;
  --text-secondary-color: #7B809A;
}

@border-radius-base: 10px;@btn-height-base: 48px;@btn-default-ghost-color: @primary-color;@btn-default-ghost-border: @primary-color;@checkbox-border-radius: 4px;@divider-orientation-margin: 0px;@font-size-base: 18px;@font-family: "Nunito Sans", sans-serif;@input-height-base: 48px;@input-placeholder-color: #7B809A;@modal-confirm-title-font-size: 14px;@modal-confirm-body-padding: 32px 32px 24px;@primary-color: #3A36DB;@popover-padding-horizontal: 0px;@table-header-color: #4F5B67;@table-header-bg: white;@table-header-cell-split-color: transparent;@table-font-size: 12px;