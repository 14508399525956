.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.redDot {
  height: 7px;
  margin-left: 5px;
  position: absolute;
  width: 7px;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
}

.navigationBtnWrapper {
  align-items: self-end;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.navigationBtn {
  width: 122px;
}

.navigationBtn:first-of-type {
  margin-right: 10px;
}

.errors {
  color: red;
  margin-bottom: 30px;
  text-align: right;
}

.btnsArea {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.approveData {
  align-items: center;
  background: rgba(58, 54, 219, 0.12);
  border: 1px dashed #3A36DB;
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  width: 620px;
}

.approveData svg {
  margin-right: 7px;
}

.buttons {
  align-items: center;
  display: flex;
}

.backBtn {
  cursor: pointer;
  color: #666666;
  margin-right: 15px;
  text-decoration-line: underline;
}
