.wrapper {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 20px;
}

/* .fieldsArea {
  column-gap: 20px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-columns: 2fr 1fr 200px;
} */

.formBlock,
.smallFormBlock {
  background: #FFFFFF;
  border-radius: 8px;
  padding: 20px;
  position: relative;
}

.smallFormBlock {
  border: 3px solid var(--app-background-color);
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  padding: 5px 8px 0px 8px;
}

.formIconWrapper {
  align-items: center;
  background: linear-gradient(180deg, #3A36DB 0%, #5C5AAC 100%);
  border-radius: 12px;
  display: flex;
  height: 64px;
  justify-content: center;
  right: 10px;
  margin-top: -15px;
  position: absolute;
  width: 64px;
}

.doubleInputArea {
  column-gap: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.label {
  font-size: 14px;
}

.smallFormBlock .label {
  color: #7B809A;
  font-size: 14px;
  font-weight: 300;
}

.smallFormBlock .value {
  color: #344767;
  font-size: 20px;
  font-weight: 700;
}

.addRemoveFieldBtn {
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 2px;
  color: var(--app-primary-color);
  cursor: pointer;
  font-size: 12px;
  text-align: center;
}

.mapWrapper {
  background: linear-gradient(180deg, #3E3D45 0%, #202020 100%);
  border-radius: 12px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  max-width: 100vw;
  padding: 20px 0px;
  overflow: hidden;
}

.map {
  height: 250px;
  z-index: 100;
}
