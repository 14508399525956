.pageHeader {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.tools {
  align-items: center;
  display: flex;
}

.tools button {
  margin-left: 10px;
}

.link {
  background: #F3F8FF;
  border-radius: 12px;
  color: #1A73E8;
  font-weight: 900;
  padding: 5px 10px;
}
