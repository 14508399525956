.spinner {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
}

.formBlock {
  background: #FFFFFF;
  border-radius: 8px;
  margin-top: 20px;
  padding: 20px;
  position: relative;
}

.fieldBlock:not(:first-of-type) {
  margin-top: 30px;
}

.label {
  font-size: 14px;
}

.textEditor div:first-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.textEditor div:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  min-height: 300px;
}

.buttons {
  align-items: center;
  display: flex;
  justify-content: end;
  margin-top: 30px;
  width: 100%;
}

.buttons button {
  width: 122px;
}

.buttons button:last-of-type {
  margin-left: 10px;
}

.error {
  color: red;
  margin-right: 15px;
  text-align: right;
}
