
.pageHeader {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.tools button {
  align-items: center;
  display: flex;
}

.tools svg path {
  stroke: white;
  stroke-width: 1;
}

.content {
  background-color: white;
  padding: 96px 32px;
}

.emptyPage {
  font-size: 20px;
  padding: 30px 0px;
  text-align: center;
}

.title {
  margin-bottom: 24px;
  text-align: center;
}

.cover {
  height: 500px;
  object-fit: scale-down;
  width: 100%;
}

.imageDetails {
  color: #667085;
  font-size: 14px;
  text-align: center;
}

.sectionContent {
  margin-top: 20px;
}
