.pageHeader {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.tools {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 40px;
}

.tools button {
  margin-left: 10px;
}

.checkboxForUnknown {
  align-items: center;
  display: inline-flex;
  font-size: 14px;
  width: 160px;
}

.resultsFilter {
  margin-right: 10px;
  width: 200px !important;
}
