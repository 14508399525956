.wrapper {
  align-items: center;
  background-image: url('./../../img/signIn/bgImage.png');
  background-size: cover;
  display: flex;
  height: 100vh;
  justify-content: center;
  min-height: var(--app-min-height);
}

.contentWrapper {
  background: rgba(216, 224, 255, 0.2);
  backdrop-filter: blur(100px);
  min-width: 500px;
  padding: 13px 80px 75px 80px !important;
  position: relative;
  text-align: center;
}

.form {
  margin-top: 20px;
}

.plane {
  position: absolute;
  top: -100px;
  left: -100px;
}

.logo {
  margin-bottom: 30px;
  width: 250px;
}

.formTitle {
  color: #344767;
  white-space: pre-wrap;
}

.description {
  color: var(--text-secondary-color);
  font-size: 16px;
  margin-bottom: 40px;
  white-space: pre-wrap;
}

.rememberMe {
  align-items: center;
  display: flex;
}

.rememberMe span {
  color: var(--text-secondary-color);
  margin-bottom: 20px;
  margin-left: 10px;
  text-align: start;
}

.forgotPassword {
  color: black;
  cursor: pointer;
  display: block;
  font-size: 14px;
  margin-bottom: 20px;
  text-align: right;
}

.submitBtn {
  width: 100%;
}

.additionalLink {
  color: var(--text-secondary-color);
  font-size: 14px;
}

.additionalLink a {
  font-weight: 600;
}
