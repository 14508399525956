.spinner {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
}

.mainInfoArea {
  column-gap: 20px;
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.formBlock {
  background: #FFFFFF;
  border-radius: 8px;
  margin-top: 20px;
  padding: 20px;
  position: relative;
}

.label {
  font-size: 14px;
}

.doubleInputArea {
  column-gap: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.buttonsAreaWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.buttonsAreaWrapper button {
  width: 122px;
}

.buttonsAreaWrapper button:last-of-type {
  margin-left: 15px;
}

.checkboxWrapper {
  align-items: center;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  display: flex;
  margin-bottom: 24px;
  padding: 0px 11px;
}

.checkboxWrapper div:first-of-type {
  margin-bottom: 1px;
}

.checkboxWrapper .label {
  font-size: 16px;
  margin-left: 10px;
}

.categoryOption div {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.categoryFormError {
  color: red;
  margin: 20px 0px;
  text-align: center;
}

.bannerColorWrapper {
  align-items: center;
  display: flex;
  margin: 10px 0px;
}

.bannerColorSample {
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  height: 40px;
  margin-right: 10px;
  width: 80px;
}

.bannerFromCodeEmpty {
  align-items: center;
  background: #FAFAFA;
  border: 1px dashed #D9D9D9;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.85);
  display: flex;
  font-size: 16px;
  height: 110px;
  justify-content: center;
}

.bannerFromCode {
  height: 82px;
  overflow: hidden;
}

.error {
  color: red;
  margin-top: 10px;
  text-align: right;
}
