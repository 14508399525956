.spinner {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
}

.mainInfoArea {
  display: flex;
  flex-direction: column;
}

.formBlock {
  background: #FFFFFF;
  border-radius: 8px;
  margin-top: 20px;
  padding: 20px;
  position: relative;
}

.label {
  font-size: 14px;
}

.doubleField {
  column-gap: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 500px;
}

.field {
  margin-bottom: 20px;
  max-width: 500px;
}

.fieldValue {
  border-radius: 10px;
  border: 1px solid #D9D9D9;
  min-height: 40px;
  padding: 8px;
}

.statisticWrapper {
  column-gap: 10px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 40px;
}

.statisticCard {
  background-color: white;
  border-radius: 8px;
  border: 1px solid var(--Gray-200, #EAECF0);
  box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06);
  display: flex;
  flex-direction: column;
  padding: 12px;
}

.statisticTitle {
  color: var(--Gray-900, #101828);
  font-size: 18px;
  font-weight: 700;
}

.statisticValueWrapper {
  align-items: center;
  display: flex;
  color: var(--Gray-900, #101828);
  font-weight: 400;
}

.statisticValueWrapper .value {
  font-size: 32px;
  margin-right: 8px;
}

.statisticValueWrapper .name {
  font-size: 18px;
}

.cancelBtn {
  align-self: flex-end;
  margin-top: 60px;
  width: fit-content;
}
