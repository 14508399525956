.wrapper {
  background: #FFFFFF;
  border-radius: 8px;
  padding: 20px 20px 75px 20px;
  width: 620px;
}

.wrapper.previews {
  width: 100%;
}

.wrapper h2 {
  font-size: 20px;
  font-weight: 600;
  margin: 0px;
  margin-bottom: 35px;
}

.previews .imagesAreaWrapper {
  display: grid;
  grid-template-columns: repeat(4, 184px);
  column-gap: 25px;
  row-gap: 15px;
}

.upload {
  margin: 0px 90px;
}

.previews .upload {
  margin: 0px;
}

.uploadDragger {
  padding: 16px;
}

.uploadDragger.addMore {
  height: 184px;
  width: 184px;
}

.uploadTitle {
  font-size: 16px;
  margin-bottom: 8px;
}

.uploadDescription {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}

.imagePreviewWrapper {
  column-gap: 30px;
  display: grid;
  grid-template-columns: 184px 1fr;
  margin-bottom: 20px;
  position: relative;
}

.imagePreviewWrapper img {
  border-radius: 10px;
  height: 184px;
  object-fit: cover;
  width: 184px;
}

.removePhotoBtn {
  align-items: center;
  background: #FFFFFF;
  border-top-right-radius: 10px;
  cursor: pointer;
  display: flex;
  height: 36px;
  justify-content: center;
  position: absolute;
  top: 0px;
  right: 0px;
  width: 36px;
}
