.wrapper {
  height: 100%;
  overflow: hidden;
}

.label {
  font-size: 14px;
  margin-bottom: 5px;
  margin-top: 20px;
}

.defaultLocationWarning {
  color: gray;
  font-size: 12px;
  margin-left: 10px;
}

.contentWrapper {
  background: #FFFFFF;
  border-radius: 8px;
  padding: 20px;
  position: relative;
  margin-top: 20px;
}

.userAppWrapper {
  height: 100%;
}

.userAppWrapper iframe {
  border: none;
  height: 100vh;
  max-height: 1000px;
  min-height: 500px;
  width: 100%;
}
