.label {
  font-size: 14px;
}

.addRemoveFieldBtn {
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 2px;
  color: var(--app-primary-color);
  cursor: pointer;
  font-size: 12px;
  text-align: center;
}
