
.title {
  color: #101828;
  font-size: 18px;
  font-weight: 600;
}

.description {
  color: #667085;
  font-size: 14px;
  font-weight: 400;
  margin-top: 8px;
}

.label {
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-weight: 400;
  margin-top: 20px;
}

.uploadDragger {
  margin-top: 30px;
}

.validationMessage {
  color: #667085;
  margin-top: 32px;
  text-align: right;
}

.btnArea {
  column-gap: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 12px;
}
