.mainColumn {
  font-size: 14px;
}

.link {
  background: #F3F8FF;
  border-radius: 12px;
  color: #1A73E8;
  font-weight: 900;
  padding: 5px 10px;
}

.actions {
  display: flex;
}

.actions a {
  cursor: pointer;
  margin-right: 10px;
}

.actions svg {
  cursor: pointer;
}

.notApprovedMarker {
  height: 10px;
  margin-bottom: 12px;
  margin-right: 10px;
  width: 10px;
}
