.pageHeader {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.tools {
  align-items: center;
  display: flex;
}

.email {
  background: #F3F8FF;
  border-radius: 12px;
  color: #1A73E8;
  font-size: 12px;
  font-weight: 900;
  padding: 7px 10px 7px 10px;
  width: fit-content;
}

.emailSubscription {
  align-items: center;
  border-radius: 12px;
  display: flex;
  font-size: 12px;
  height: fit-content;
  justify-content: center;
  padding: 5px 10px;
  width: 40px;
}

.emailSubscription.subscribed {
  background: #E6FBD9;
  color: #0F5B1D;
}

.emailSubscription.unsubscribed {
  background: #FFF9CF;
  color: #7B5C03;
}


