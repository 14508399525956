.spinner {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
}

.mainInfoArea {
  column-gap: 20px;
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.formBlock {
  background: #FFFFFF;
  border-radius: 8px;
  margin-top: 20px;
  padding: 20px;
  position: relative;
}

.label {
  font-size: 14px;
}

.field {
  margin-bottom: 20px;
}

.fieldValue {
  border-radius: 10px;
  border: 1px solid #D9D9D9;
  min-height: 40px;
  padding: 8px;
}

.photoList {
  column-gap: 24px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 184px);
  row-gap: 24px;
}

.imageWithOptions {
  position: relative;
  user-select: none;
}

.options {
  align-items: center;
  background-color: rgba(1, 1, 1, 0.8);
  display: none;
  height: 100%;
  justify-content: center;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}

.options svg {
  cursor: pointer;
}

.imageWithOptions:hover .options {
  display: flex;
}

.photo {
  object-fit: contain;
  height: 184px;
  width: 184px;
}

.cruiseNameOption div:first-of-type {
  height: fit-content;
  overflow: hidden;
  white-space: break-spaces;
}

.datePickerFooter {
  color: #0068FF;
  cursor: pointer;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  white-space: pre-wrap;
}

.datePickerFooter:hover {
  color: #2986ff;
}

.buttons {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.buttons button {
  width: 122px;
}

.buttons button:last-of-type {
  margin-left: 10px;
}
