.pageHeader {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.tools {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.tools button {
  margin-left: 10px;
}

.postLink {
  background: #F3F8FF;
  border-radius: 12px;
  color: #0068FF;
  font-size: 12px;
  font-weight: 600;
  padding: 5px 10px;
  width: fit-content;
}
