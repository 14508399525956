.wrapper {
  display: flex;
  flex-direction: column;
}

.blockTitle {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 7px;
}

.colorOption span {
  align-items: center;
  display: flex;
  margin-bottom: 10px;
}

.colorSample {
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  height: 44px;
  margin-left: 8px;
  width: 44px;
}
