.pageHeader {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.tools {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 40px;
}

.tools button{
  margin-left: 10px;
}
