.verticalSuggestions {
  display: flex;
  flex-direction: column;
  height: 30px;
  overflow: visible;
  z-index: 100;
}

.verticalSuggestions input {
  height: 30px;
  /* max-width: 600px; */
}

.suggestionsWrapper {
  background-color: white;
  border: 1px solid #f1f1f1;
  /* margin-top: -20px; */
  height: 300px;
  padding: 8px;
  z-index: 10000;
}

.suggestionsWrapper div {
  border-bottom: 1px solid #f1f1f1;
  cursor: pointer;
}
