.label {
  font-size: 14px;
}

.uploadDragger {
  height: 190px !important;
}

.imagePreviewWrapper {
  align-items: center;
  display: flex;
  height: 170px;
  justify-content: center;
  position: relative;
}

.imagePreviewWrapper img {
  max-height: 160px;
  max-width: 90%;
}

.removePhotoBtn {
  align-items: center;
  border-top-right-radius: 10px;
  cursor: pointer;
  display: flex;
  height: 36px;
  justify-content: center;
  position: absolute;
  top: -15px;
  right: 0px;
  width: 36px;
}
