.selectBtn {
  margin-top: 10px;
}

.screenNavigation {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.navigateToPrevScreen {
  cursor: pointer;
  margin-right: 40px;
}

.navigateToNextScreen {
  cursor: pointer;
  margin-left: 40px;
}

.currentScreenCount {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.modalBtnsArea {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.modalBtnsArea button {
  width: 122px;
}

.screenWrapper {
  height: 700px;
  margin: 0 auto;
  position: relative;
  width: 960px;
}

.screenImg {
  height: 100%;
  width: 100%;
}

.bannerSlot {
  align-items: center;
  background: #f2f2f2;
  border-radius: 9px;
  border: 1px dashed var(--active, #191559);
  color: #191559;
  cursor: pointer;
  font-size: 10px;
  font-weight: 500;
  display: flex;
  height: 5.6%;
  justify-content: center;
  letter-spacing: 0.08px;
  position: absolute;
}

.bannerSlot.disabled {
  background-color: #ECF3F9;
  border: 1px dashed gray;
  color: gray;
  cursor: default;
}

.emptySlotForPagePreview {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
