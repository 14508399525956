.modal h3{
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  margin: 20px 0px;
}

.modalBtnWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
}

.modalBtnWrapper button {
  width: 122px;
}

.modalBtnWrapper button:first-of-type {
  margin-right: 15px;
}

.colorExample {
  cursor: pointer;
  height: 50px;
  width: 50px;
}

.sketchPicker {
  border: 1px solid gray;
  border-radius: 5px;
  bottom: 0px;
  display: none;
  right: 30px;
  padding: 0px 0px 2px 0px;
  position: absolute;
}

.sketchPicker.visible {
  display: block;
}

.sketchPicker button {
  margin-top: 5px;
  margin-right: 10px;
}
