.wrapper {
  display: grid;
  grid-template-columns: 1fr;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
}

.formAreaWrapper {
  column-gap: 20px;
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.formBlock {
  background: #FFFFFF;
  border-radius: 8px;
  padding: 20px;
  position: relative;
  margin-top: 20px;
}

.label {
  font-size: 14px;
}

.buttons {
  align-items: center;
  display: flex;
  justify-self: end;
  margin-top: 20px;
}

.buttons button {
  width: 122px;
}

.buttons button:last-of-type {
  margin-left: 10px;
}
