.wrapper {
  display:flex;
  flex-direction :column;
  height: 100%;
  justify-content: space-between;
}

.wrapper h1 {
  margin-bottom: 30px;
}

.contentWrapper {
  height: calc(100% - 150px);
}

.form {
  background: #FFFFFF;
  border-radius: 8px;
  height: 100%;
  padding: 20px 30px;
}

.form h2 {
  color: rgba(0, 0, 0, 0.8);
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 30px;
}

.personalInfoWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-bottom: 3px solid #F7F7FB;
  margin-bottom: 30px;
}

.avatarWrapper {
  align-items: center;
  display: flex;
  justify-content: center;
}

.avatarWrapper img {
  border-radius: 50%;
  height: 140px;
  margin-right: 40px;
  object-fit: contain;
  width: 140px;
}

.changeAvatarBtn {
  border: 1px solid var(--app-primary-color);
  border-radius: 10px;
  color: var(--app-primary-color);
  cursor: pointer;
  margin-right: 40px;
  padding: 9px 33px;
}

.removeAvatarBtn {
  color: var(--app-primary-color);
  cursor: pointer;
  margin-bottom: 20px;
}

.newPasswordWrapper {
  margin-bottom: 30px;
}

.buttonsArea {
  display: flex;
  justify-content: flex-end;
}

.buttonsArea button {
  width: 122px;
}

.buttonsArea button:first-child {
  margin-right: 10px;
}
