.formBlock {
  background: #FFFFFF;
  border-radius: 8px;
  margin-top: 20px;
  padding: 20px;
  position: relative;
}

.photoList {
  column-gap: 24px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 184px);
  row-gap: 24px;
}

.imageWithOptions {
  position: relative;
  user-select: none;
}

.options {
  align-items: center;
  background-color: rgba(1, 1, 1, 0.8);
  display: none;
  height: 100%;
  justify-content: center;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}

.options svg {
  cursor: pointer;
}

.imageWithOptions:hover .options {
  display: flex;
}

.photo {
  object-fit: contain;
  height: 184px;
  width: 184px;
}
