.mainInfoArea {
  column-gap: 20px;
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.mainInfoArea.modalView {
  grid-template-columns: 1fr;
}

.formBlock {
  background: #FFFFFF;
  border-radius: 8px;
  margin-top: 20px;
  padding: 20px;
  position: relative;
}

.modalView .formBlock {
  margin-top: 0px;
  padding: 0px;
}

.label {
  font-size: 14px;
}

.doubleInputArea {
  column-gap: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.mapWrapper {
  background: linear-gradient(180deg, #3E3D45 0%, #202020 100%);
  border-radius: 12px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  grid-column-start: 1;
  grid-column-end: 3;
  max-width: 100vw;
  margin-top: 20px;
  padding: 20px 0px;
  overflow: hidden;
}

.modalView .mapWrapper {
  grid-column-end: 1;
}

.map {
  height: 250px;
  z-index: 100;
}

.buttonsAreaWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.buttonsAreaWrapper button {
  width: 122px;
}

.buttonsAreaWrapper button:last-of-type {
  margin-left: 15px;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
}
