.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.formBlock {
  background: #FFFFFF;
  border-radius: 8px;
  padding: 20px;
  position: relative;
  margin-top: 20px;
}

.sectionWrapper {
  column-gap: 30px;
  display: grid;
  grid-template-columns: 3fr 2fr;
  row-gap: 10px;
}

.label {
  font-size: 14px;
}

.textEditor div:first-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.textEditor div:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  min-height: 144px;
}

.buttons {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.buttons button {
  width: 122px;;
}

.buttons button:last-of-type {
  margin-left: 10px;
}

.deleteBlock {
  color: #FF0000;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  grid-column: 1/3;
  margin-top: 20px;
  margin-bottom: -10px;
  text-align: center;
}

.addBlockBtn {
  color: #3A36DB;
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
}

.error {
  color: red;
  margin: 10px 0px;
  text-align: right;
}
