.blockTitle {
  font-size: 20px;
  margin-bottom: 10px;
}

.blockTitle.advertisement {
  font-size: 14px;
  margin-bottom: 10px;
}

.optionsWrapper {
  display: flex;
  justify-content: space-between; 
}

.additionalInfoWrapper {
  display: flex;
}

.additionalInfoWrapper div:last-of-type {
  margin-left: 20px;
}

.additionalInfo {
  color: rgb(72, 72, 72);
  font-size: 14px;
}

.error {
  color: red;
  text-align: right;
}

.fieldsWrapper {
  /* min-height: 230px */
  margin-bottom: 20px;
}

.explanationText {
  font-size: 14px;
  margin-bottom: 5px;
}

.globalOptionText {
  align-items: center;
  display: flex;
  height: 150px;
  justify-content: center;
}

.doubleInputArea {
  column-gap: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 20px;
}

.optionExplanation {
  margin: 0px 0px 20px 0px;
  text-align: center;
}

.paginationWrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 7px;
  width: 65%;
}

.paginationLoadMore {
  color: #3A36DB;
  cursor: pointer;
  font-weight: 700;
  text-decoration: underline;
}
