.formBlock {
  background: #FFFFFF;
  border-radius: 8px;
  margin-top: 20px;
  padding: 20px;
  position: relative;
  width: 600px
}

.buttonsAreaWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.buttonsAreaWrapper button {
  width: 122px;
}

.buttonsAreaWrapper button:last-of-type {
  margin-left: 15px;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
}
