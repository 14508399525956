.wrapper {
  background-color: #FFFFFF;
  display: grid;
  grid-template-rows: 95px auto 95px;
  height: 100dvh;
  position:fixed;
  top: 0px;
  left: 0px;
  width: 240px;
}

.logo {
  align-items: center;
  display: flex;
  justify-content: center;
}

.logo img {
  width: 70%;
}

.menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* logo and logout blocks are 95px each */
  height: calc(100dvh - 95px - 95px);
  overflow-y: auto;
}

.menuItem {
  align-items: center;
  cursor: pointer;
  column-gap: 25px;
  display: grid;
  grid-template-columns: 24px auto;
  padding: 16px 24px;
}

.menuItem {
  margin-bottom: 8px;
}

.menuItem svg path {
  fill: #9BA1AA;
}

.menuItem.withStroke svg path {
  stroke: #9BA1AA;
  fill: none;
}

.menuItem.active {
  background: #F7F7FB;
  border-right: 4px solid var(--app-primary-color);
}

.menuItem.active svg path {
  fill: var(--app-primary-color);
}

.menuItem.active.withStroke svg path {
  stroke: var(--app-primary-color);
  fill: none;
}

.menuItem.active .itemTitle {
  color: #0D0D54;
}

.itemTitle,
.userName {
  color: #4F5B67;
  font-size: 16px;
  font-weight: 900;
}

.user {
  align-items: center;
  border-top: 1px solid #f1f1f1;
  display: flex;
  padding: 0px 24px;
}

.avatar {
  border: 2px solid #D8D8FE;
  border-radius: 50%;
  height: 32px;
  margin-right: 15px;
  object-fit: contain;
  width: 32px;
}
