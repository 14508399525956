.mainDataWrapper {
  column-gap: 20px;
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.bannerFromCodeEmpty {
  align-items: center;
  background: #FAFAFA;
  border: 1px dashed #D9D9D9;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.85);
  display: flex;
  font-size: 16px;
  height: 110px;
  justify-content: center;
}

.bannerFromCode {
  max-height: 300px;
  overflow: auto;
}
