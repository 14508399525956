.pageHeader {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.tools {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 40px;
}

.tools button{
  margin-left: 10px;
}

.distanceFieldWrapper {
  align-items: center;
  background: white;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  cursor: pointer;
  color: #7B809A;
  display: flex;
  font-size: 18px;
  height: 48px;
  justify-content: space-between;
  margin-right: 15px;
  padding: 11.5px;
  position: relative;
  width: 190px;
}

.distanceFieldWrapper.active {
  border-color: #665fe8;
  box-shadow: 0 0 0 2px rgba(58, 54, 219, 0.2);
  outline: 0;
}

.distanceField {
  border-radius: 20px;
  background: #FFF;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
  left: -50px;
  padding: 20px 12px;
  position: absolute;
  top: 52px;
  width: 290px;
  z-index: 1000;
}

.distanceFieldTitle {
  border-bottom: 2px solid #F2F2F4;
  color: #000;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 12px;
  padding-bottom: 8px;
}

.distanceFieldButtons {
  align-items: center;
  display: flex;
}

.distanceFieldButtons button {
  margin-top: 20px;
  margin-left: 0px;
  width: 100%;
}

.distanceFieldButtons button:first-child {
  margin-right: 8px;
}

.resultsFilter {
  margin-right: 10px;
  width: 200px !important;
}
