.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
}

.redDot {
  height: 7px;
  margin-left: 5px;
  position: absolute;
  width: 7px;
}

.formBlock {
  background: #FFFFFF;
  border-radius: 8px;
  padding: 20px;
  position: relative;
  margin-top: 20px;
}

.doubleInputArea {
  column-gap: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.label {
  font-size: 14px;
}

.error {
  color: red;
  margin-top: 30px;
  text-align: right;
}

.btnsArea {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  width: 100%;
}

.approveData {
  align-items: center;
  background: rgba(58, 54, 219, 0.12);
  border: 1px dashed #3A36DB;
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  width: 620px;
}

.approveData svg {
  margin-right: 7px;
}

.buttons {
  align-items: center;
  display: flex;
}

.buttons button {
  width: 122px;;
}

.buttons button:last-of-type {
  margin-left: 10px;
}
