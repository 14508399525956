.mainInfoArea {
  column-gap: 20px;
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.formBlock {
  background: #FFFFFF;
  border-radius: 8px;
  margin-top: 20px;
  padding: 20px;
  position: relative;
}

.label {
  font-size: 14px;
}

.field {
  margin-bottom: 20px;
}

.termsAndConditions {
  border-radius: 10px;
  border: 1px solid #D9D9D9;
  min-height: 40px;
  padding: 8px;
}

.buttons {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.buttons button {
  width: 122px;
}

.buttons button:last-of-type {
  margin-left: 10px;
}
